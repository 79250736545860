import React from "react";
import { connect } from "react-redux";
import * as reduxClientActions from "./redux-client.actions.js";
import { clientObservable } from "./client.resource.js";
import Cancelable from "react-disposable-decorator";
import { catchAsyncStacktrace } from "auto-trace";

/* The client menu is now it's own app-loader-ui child app -- this component is now just responsible for
 * feeding the active client into the rest of end-user-forms-ui
 */
@connect((state) => ({
  context: state.context,
}))
@Cancelable
export default class ClientMenu extends React.Component {
  componentDidMount() {
    this.props.cancelWhenUnmounted(
      clientObservable(this.props.match.params.clientId).subscribe(
        (client) =>
          this.props.dispatch(reduxClientActions.setReduxClient(client)),
        catchAsyncStacktrace()
      )
    );
  }
  render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
  componentWillUnmount() {
    this.props.dispatch(reduxClientActions.resetReduxClient());
  }
}
