import * as actionTypes from "./resolution-cases.types.js";
import {
  getResolutionCase,
  patchResolutionCase,
} from "./resolution-cases.resource.js";
import { catchAsyncStacktrace } from "auto-trace";

export function updateResolutionCase(resolutionCase) {
  return {
    type: actionTypes.NEW_ENGAGEMENT,
    resolutionCase: resolutionCase,
  };
}

export function fetchResolutionCase(clientId, resolutionCaseId, next) {
  return (dispatch) => {
    getResolutionCase(clientId, resolutionCaseId).subscribe(
      (resolutionCase) => {
        dispatch(updateResolutionCase(resolutionCase));
        if (next) next(resolutionCase);
      },
      (err) => catchAsyncStacktrace(err)
    );
  };
}

export function consumeCollectionCase({ clientId, resolutionCaseId, next }) {
  return (dispatch) => {
    const data = {
      collection_case_consumed: true,
    };

    patchResolutionCase({ clientId, resolutionCaseId, data }).subscribe(
      (resolutionCase) => {
        dispatch(updateResolutionCase(resolutionCase));
        if (next) next(resolutionCase);
      },
      catchAsyncStacktrace()
    );
  };
}

export function resetResolutionCase() {
  return {
    type: actionTypes.RESET_ENGAGEMENT,
  };
}
