import fetcher, { forceBustCache } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";

export function getSection(clientId, resolutionCaseId, sectionId) {
  return new Promise((resolve, reject) => {
    fetcher(
      `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections/${sectionId}`
    )
      .then((resp) => {
        if (resp.ok) {
          resp
            .json()
            .then((json) => {
              resolve(json.resolution_case_sections);
            })
            .catch(reject);
        } else {
          reject(resp);
        }
      })
      .catch(reject);
  });
}

export function putSection(
  clientId,
  resolutionCaseId,
  sectionId,
  section,
  notifications
) {
  return new Promise((resolve, reject) => {
    const body = {
      resolution_case_sections: section,
    };
    if (notifications) {
      body.notifications = notifications;
    }

    fetcher(
      `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections/${sectionId}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
      }
    )
      .then((resp) => {
        if (resp.ok) {
          resp
            .json()
            .then((json) => {
              forceBustCache(
                `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`
              );
              resolve(json.resolution_case_sections);
            })
            .catch(reject);
        } else {
          reject(resp);
        }
      })
      .catch(reject);
  });
}
