import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AsyncLoader from "src/common/async-loader.component.js";
import CSSF_Sections from "./cssf-sections.component.js";
import {
  getClientSurveySourceFormId,
  clearClientSurveySourceFormId,
  patchClientSurveyAction,
  patchSurveyProperties,
  openSurveyOptions,
} from "../client-survey.actions.js";
import { negate, property, get, filter } from "lodash";
import { createNoAccessClientSurvey } from "../create-survey/create-survey.actions.js";
import { getSurveyGroup, getSurveySourceForm } from "../client-survey.helper";
import { getSurveyDownloadTitle } from "src/resources/tasks.resource.js";
import Cancelable from "react-disposable-decorator";
import { catchAsyncStacktrace } from "auto-trace";
import { UserTenantProps } from "cp-client-auth!sofe";

const asyncChecks = [
  (props) => {
    const href = window.location.href;
    if (
      props.match.params.sectionId &&
      props.match.params.subsectionIndex >= 0
    ) {
      return true;
    } else if (
      href.includes(`${props.baseUrl}/submit`) ||
      href.includes(`${props.baseUrl}/welcome`) ||
      href.includes(`${props.baseUrl}/upload`) ||
      href.includes(`${props.baseUrl}/file`)
    ) {
      return true;
    } else {
      return false;
    }
  },
  negate(property("engagement.isDefaultResolutionCase")),
];

@UserTenantProps()
@Cancelable
@withRouter
@connect((state) => ({
  clientSurvey: state.clientSurvey,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  sourceFormLayout: state.sourceFormLayout,
  resolutionCase: state.resolutionCase,
  clientUsers: state.clientSurvey.clientUsers,
}))
export default class CSSF_SectionsWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.boundActions = bindActionCreators(
      { patchClientSurveyAction, patchSurveyProperties, openSurveyOptions },
      props.dispatch
    );

    this.state = { taskName: null };
  }

  componentDidMount() {
    if (window.location.href.match(/sections$/)) {
      const { baseUrl } = this.props;

      this.props.history.replace(`${baseUrl}/sections/0/subsections/0`);
    }

    //team members only
    if (this.props.loggedInUser.role !== "Client") {
      this.props.cancelWhenUnmounted(
        getSurveyDownloadTitle(this.props.resolutionCaseId).subscribe(
          (results) => {
            const filteredResults = filter(
              results,
              (item) => !item.parent_task_id
            );

            this.setState({ taskName: get(filteredResults[0], "name") });
          },
          catchAsyncStacktrace()
        )
      );
    }
    if (this.props.clientSurvey.survey.notCreated) {
      this.props.dispatch(
        createNoAccessClientSurvey(
          this.props.clientId,
          this.props.resolutionCaseId,
          this.props.surveyMetaData
        )
      );
    }
  }

  componentDidUpdate(prevProps) {
    checkGetSourceFormId(this.props);
    if (
      !prevProps.clientSurvey.survey.notCreated &&
      this.props.clientSurvey.survey.notCreated
    ) {
      this.props.dispatch(
        createNoAccessClientSurvey(
          this.props.clientId,
          this.props.resolutionCaseId,
          this.props.surveyMetaData
        )
      );
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearClientSurveySourceFormId());
  }

  render() {
    return (
      <AsyncLoader {...this.props} asyncChecks={asyncChecks}>
        <CSSF_Sections
          {...this.props}
          actions={this.boundActions}
          sections={this.props.sourceFormLayout.sections}
          taskName={this.state.taskName}
          displayingInClientPortal={this.props.displayingInClientPortal}
        />
      </AsyncLoader>
    );
  }
}

function checkGetSourceFormId(props) {
  const shouldGetId = !props.clientSurvey.clientSurveySourceFormId;
  const gettingId = props.clientSurvey.gettingSourceFormId;
  const canGetId =
    props.version.id &&
    props.revision.id &&
    !props.resolutionCase.isDefaultResolutionCase &&
    props.surveyMetaData.surveyType;

  if (shouldGetId && !gettingId && canGetId) {
    props.dispatch(
      getClientSurveySourceFormId(
        props.clientId,
        props.resolutionCaseId,
        props.version.id,
        props.revision.id,
        getSurveySourceForm(
          props.surveyMetaData.surveyType,
          props.surveyMetaData.surveyData,
          props.resolutionCase
        ),
        getSurveyGroup(props.surveyMetaData.surveyType)
      )
    );
  }
}
