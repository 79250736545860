import React from "react";
import styles from "./quick-look.styles.css";
import { CprTooltip } from "canopy-styleguide!sofe";

import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import DrawerContainer from "../../common/drawer/drawer-container.component";
import DrawerTitle from "../../common/drawer/drawer-title.component";
import { always, toggle } from "kremling";
import Block from "../blocks/block.component.js";
import { clamp } from "../../common/multi-line-truncation-helper.js";

export default class BlockDisplay extends React.Component {
  state = {
    isBlockDisplayOpen: false,
    disableTooltip: true,
  };

  constructor() {
    super();
    this.textRef = React.createRef();
  }

  componentDidMount() {
    clamp(this.textRef.current, {
      clamp: 2.1,
      onTruncate: this.enableTooltip,
      useNativeClamp: false,
    });
  }

  openBlockDisplay = () => {
    this.setState({
      isBlockDisplayOpen: true,
    });
  };

  closeBlockDisplay = () => {
    this.setState(
      {
        isBlockDisplayOpen: false,
      },
      () => this.props.actions.updateAnswer(null, this.props.question)
    );
  };

  handleSpacebarPress = (event) => {
    if (event.key === " " && !this.state.isBlockDisplayOpen) {
      event.preventDefault();
      this.openBlockDisplay();
    }
  };

  enableTooltip = () => {
    this.setState({ disableTooltip: false });
  };

  render() {
    const {
      question,
      actions,
      inTable,
      sectionId,
      summaryTable,
      formName,
      clientId,
      answers,
      alternateAnswerSets,
      overrideAnswerSet,
      questionsToShowRequiredWarnings,
      resolutionCaseId,
      noticeId,
      version,
      revision,
      sourceFormId,
      taskId,
      taxPrep,
      dynamicData,
      sectionResize,
    } = this.props;

    return (
      <React.Fragment>
        <div
          className={toggle(
            fixedTableStyles.fixedTableQuicklook,
            styles.quickLook,
            inTable
          )}
          onClick={this.openBlockDisplay}
          onKeyDown={this.handleSpacebarPress}
          tabIndex="0"
        >
          <CprTooltip
            html={question.label}
            disabled={this.state.disableTooltip}
            delayTime={600}
          >
            <div
              className={toggle(
                fixedTableStyles.fixedTableQuicklookMaxWidth,
                styles.maxWidth,
                inTable
              )}
              ref={this.textRef}
            >
              {question.label}
            </div>
          </CprTooltip>
          <i className="cps-icon cps-icon-right-caret" />
        </div>
        <DrawerContainer
          isOpen={this.state.isBlockDisplayOpen}
          onClose={this.closeBlockDisplay}
        >
          <DrawerTitle
            isDrawerOpen={this.state.isBlockDisplayOpen}
            title={question.label}
            onClose={this.closeBlockDisplay}
          />
          {question.blocks.map((block, i) => (
            <Block
              parentName={"SectionRoot"}
              blockIndex={i}
              sectionId={sectionId}
              summaryTable={summaryTable}
              block={block}
              formName={formName}
              clientId={clientId}
              key={`${block.name}-${block.id}`}
              answers={answers}
              question={question}
              alternateAnswerSets={alternateAnswerSets}
              overrideAnswerSet={overrideAnswerSet}
              questionsToShowRequiredWarnings={questionsToShowRequiredWarnings}
              actions={actions}
              resolutionCaseId={resolutionCaseId}
              noticeId={noticeId}
              version={version}
              revision={revision}
              sourceFormId={sourceFormId}
              taskId={taskId}
              taxPrep={taxPrep}
              dynamicData={dynamicData}
              numBlocks={question.blocks.length}
              sectionResize={sectionResize}
            />
          ))}
        </DrawerContainer>
      </React.Fragment>
    );
  }
}
