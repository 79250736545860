import React, { lazy, Suspense } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get, partial, isEqual, isEmpty } from "lodash";
import Cancelable from "react-disposable-decorator";
import canopyUrls from "canopy-urls!sofe";
import { CpLoader, CpButton } from "canopy-styleguide!sofe";
import { hasAccess } from "cp-client-auth!sofe";
import { Scoped, k } from "kremling";
import { catchAsyncStacktrace } from "auto-trace";
import { concatMap } from "rxjs/operators";
import { from } from "rxjs";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from "single-spa";
import { featureEnabled } from "feature-toggles!sofe";

import Section from "../source-forms/sections/section.component.js";
import blockStyles from "../source-forms/blocks/block.styles.css";
import OverlaySectionFooter from "./overlay-section-footer.component";
import TaxFormPages from "./tax-form-pages.component";
import { getSvgPages } from "../tax-forms/svg-pages.resource";
import { generateFormPdf } from "src/tax-forms/tax-form.resource";
import { notifyAnalytics } from "../resources/analytics.resource";

import * as uiFormsActions from "../source-forms/ui-forms.actions.js";
import * as answerActions from "../source-forms/answers/answer.actions.js";
import * as summaryTableActions from "../source-forms/summary-table/summary-table.actions.js";
import * as sectionActions from "../source-forms/sections/section.actions.js";
import * as dynamicDataActions from "src/source-forms/dynamic-data.actions.js";
import { getSourceForm } from "../source-forms/source-form-layout.actions.js";
import { formSetMap } from "../tax-forms/tax-return.helper.js";
import {
  answerLoadingAsObservable,
  forceFetchSectionAsObservable,
} from "../source-forms/answers/answer.actions";

const SaveToCanopyFilesDropdown = lazy(() =>
  SystemJS.import("docs-ui").then((m) => m.saveToCanopyFiles())
);

@Cancelable
@connect((state) => ({
  section: state.section.activeSection,
  answers: state.answers,
  sourceFormLayout: state.sourceFormLayout,
  loggedInUser: state.context.loggedInUser,
  tenant: state.context.tenant,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  uiFormsState: state.uiForms,
  summaryTable: state.summaryTable,
  dynamicData: state.dynamicData,
}))
export default class OverlaySection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingSection: false,
      fetchingNewSourceForm: false,
      lastFetchTime: new Date().getTime(),
      lastFetchParams: null,
      overrides: [],
      svgPages: [],
    };

    this.actions = {
      ...bindActionCreators(uiFormsActions, this.props.dispatch),
      ...bindActionCreators(answerActions, this.props.dispatch),
      ...bindActionCreators(summaryTableActions, this.props.dispatch),
      ...bindActionCreators(sectionActions, this.props.dispatch),
      ...bindActionCreators(dynamicDataActions, this.props.dispatch),
    };
  }

  componentDidMount() {
    this.mounted = true;

    this.props.cancelWhenUnmounted(
      answerLoadingAsObservable.subscribe((res) => {
        if (this.state.fetchingNewSourceForm !== res) {
          this.setState({ fetchingNewSourceForm: res });
        }
      })
    );

    forceFetchSectionAsObservable.subscribe(() => {
      this.fetchSection(this.props, 0);
    });

    if (get(this.props, "sourceFormLayout.taxForm")) {
      this.getPages();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.props.dispatch(sectionActions.resetSourceFormSection());
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      get(prevProps, "sourceFormLayout.taxForm") !==
      get(this.props, "sourceFormLayout.taxForm")
    ) {
      this.getPages();

      notifyAnalytics(
        `Notice.form_${this.props.sourceFormLayout.taxForm}_edited.1.0`,
        {},
        "notices",
        "notices"
      );
    }

    let activeSectionLoaded =
      this.props.section.id &&
      prevProps.section.id === this.props.section.id &&
      !this.state.fetchingNewSourceForm;

    if (
      !activeSectionLoaded &&
      prevProps.section.id === get(this.props, "section.id", "0") &&
      this.props.selectedForm.id === prevProps.selectedForm.id &&
      !this.state.fetchingNewSourceForm
    ) {
      activeSectionLoaded = true;
    }

    if (
      !activeSectionLoaded &&
      this.props.version &&
      this.props.revision &&
      !this.state.fetchingSection
    ) {
      this.mounted &&
        this.setState({
          fetchingSection: true,
        });
      this.fetchSection(this.props, get(this.props, "section.id", "0"));
    }

    if (prevProps.selectedForm.id !== this.props.selectedForm.id) {
      this.mounted &&
        this.setState({
          fetchingSection: true,
        });
      this.fetchSection(this.props, 0);
    }

    if (this.state.fetchingSection && activeSectionLoaded) {
      this.mounted &&
        this.setState(
          {
            fetchingSection: false,
          },
          () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        );
    }
  }

  getPages = () => {
    const {
      client,
      resolutionCaseId,
      noticeId,
      version,
      revision,
      sourceFormLayout,
    } = this.props;

    this.props.cancelWhenUnmounted(
      getSvgPages(
        client.id,
        resolutionCaseId,
        version,
        revision,
        sourceFormLayout.taxForm,
        noticeId
      ).subscribe((resp) => {
        this.setState({ svgPages: resp.pages });
      }, catchAsyncStacktrace())
    );
  };

  fetchSection(props, sectionId) {
    const params = {
      clientId: props.client.id,
      resolutionCaseId: props.resolutionCaseId,
      noticeId: props.noticeId,
      versionId: props.version,
      revisionId: props.revision,
      sourceFormId: props.selectedForm.id,
      sectionId,
      TaxpayerType: props.client.is_business ? "Business" : "Individual",
    };

    if (this.props.section.id && isEqual(params, this.state.lastFetchParams)) {
      /* No need to retrieve the same source form more than once in a row */
      return;
    }

    this.mounted &&
      this.setState({
        fetchingSection: true,
        lastFetchParams: params,
        lastFetchTime: new Date().getTime(),
      });

    props.dispatch(
      getSourceForm(
        params,
        fetchSectionErr.bind(this),
        fetchSectionSuccess.bind(this)
      )
    );

    function fetchSectionErr(err) {
      this.mounted &&
        this.setState({
          fetchingSection: false,
        });
    }

    function fetchSectionSuccess() {
      this.mounted &&
        this.setState({
          fetchingSection: false,
        });
    }
  }

  updateSection = (section) => {
    this.props.dispatch(sectionActions.updateSourceFormSection(section));
  };

  printDownload = (action) => {
    const {
      client,
      noticeId,
      resolutionCaseId,
      version,
      revision,
      sourceFormLayout,
    } = this.props;

    const base = noticeId
      ? `${canopyUrls.getAPIUrl()}/clients/${client.id}/notices/${noticeId}`
      : `${canopyUrls.getAPIUrl()}/clients/${
          client.id
        }/resolution_cases/${resolutionCaseId}`;

    const printDownloadLink = `${base}/tax-form-pdfs/${sourceFormLayout.taxForm}?version=${version}-${revision}&action=${action}`;

    if (action === "print") {
      notifyAnalytics(
        "form.print",
        {
          formName: sourceFormLayout.taxForm,
          taxFormId: sourceFormLayout.formId,
          resolutionCaseId,
          noticeId,
        },
        "notices",
        "notices"
      );
      const printWindow = window.open(printDownloadLink);
      printWindow.print();
    } else {
      notifyAnalytics(
        "form.download",
        {
          formName: sourceFormLayout.taxForm,
          taxFormId: sourceFormLayout.formId,
          resolutionCaseId,
          noticeId,
        },
        "notices",
        "notices"
      );
      window.open(printDownloadLink);
    }
  };

  savePdf = (folderId) => {
    const {
      resolutionCaseId,
      sourceFormLayout,
      client,
      version,
      revision,
      noticeId,
    } = this.props;

    notifyAnalytics(
      "form.save_to_files",
      {
        formName: sourceFormLayout.taxForm,
        taxFormId: sourceFormLayout.formId,
        resolutionCaseId,
        noticeId,
      },
      "notices",
      "notices"
    );

    return generateFormPdf(
      client.id,
      resolutionCaseId,
      sourceFormLayout.taxForm,
      version,
      revision,
      noticeId
    ).pipe(
      concatMap((pdfFile) => {
        return from(this.createDoc(folderId, pdfFile));
      })
    );
  };

  createDoc = (folderId, file) => {
    return new Promise((resolve, reject) => {
      SystemJS.import("docs-ui!sofe").then((docsUi) => {
        docsUi.loadUploadFilesHelper().then((filesHelper) => {
          filesHelper.uploadFilesAsync([file], {
            destinationFolder: { id: folderId },
            inbox: false,
            hidden: false,
            uploadedFilesCallback: (uploadedFiles) => {
              resolve(uploadedFiles[0]);
            },
          });
        });
      });
    });
  };

  render() {
    const {
      client,
      resolutionCaseId,
      version,
      revision,
      selectedForm,
      noticeId,
      sourceFormLayout,
      loggedInUser,
    } = this.props;
    const sectionId = get(this.props, "section.id", "0");

    // Section hasn't loaded yet
    const sectionNotLoaded =
      !this.props.section.name ||
      this.state.fetchingSection ||
      this.state.fetchingNewSourceForm;
    const alternateAnswerSets = null;
    const overrideAnswerSet = null;

    const fileOrigin = noticeId ? "notices_form" : "resolution_cases_form";

    return (
      <Scoped css={css}>
        <>
          {sectionNotLoaded && (
            <div className={`${blockStyles.loaderWrapper}`}>
              <CpLoader />
            </div>
          )}
          <div
            className={sectionNotLoaded ? blockStyles.sectionNotLoaded : null}
          >
            <div className="source-form-top-bar">
              <div className="sf-top-bar-name">
                {this.props.selectedForm.name}
              </div>
              <div className="sf-top-bar-right">
                {this.props.showTaxForm ? (
                  <div style={{ display: "flex", marginRight: 8 }}>
                    {hasAccess(loggedInUser)("files_upload_move") && (
                      <div style={{ padding: "0 8px 0 8px" }}>
                        {featureEnabled("toggle_docs_parcels") && (
                          <Suspense fallback={null}>
                            <SaveToCanopyFilesDropdown
                              clientId={client.id}
                              saveHandler={this.savePdf}
                              iconClass="save-to-canopy-files-icon"
                              fileOrigin={fileOrigin}
                            />
                          </Suspense>
                        )}
                        {!featureEnabled("toggle_docs_parcels") && (
                          <Parcel
                            config={() =>
                              SystemJS.import("docs-ui!sofe").then(
                                (docsUi) =>
                                  docsUi.SaveToCanopyFilesDropdownParcel
                              )
                            }
                            clientId={client.id}
                            saveHandler={this.savePdf}
                            iconClass="save-to-canopy-files-icon"
                            fileOrigin={fileOrigin}
                            mountParcel={mountRootParcel}
                            parcelDidMount={() => {}}
                          />
                        )}
                      </div>
                    )}
                    <div style={{ padding: "0 8px 0 8px" }}>
                      <CpButton
                        icon="misc-printer"
                        onClick={() => this.printDownload("print")}
                        aria-label="Print Tax Form"
                      />
                    </div>
                    <div style={{ padding: "0 8px 0 8px" }}>
                      <CpButton
                        onClick={() => this.printDownload("download")}
                        icon="af-open-down"
                        aria-label="Download Tax Form"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      color: "var(--cps-color-secondary-text)",
                      marginRight: 16,
                      marginTop: 6,
                      fontStyle: "italic",
                    }}
                  >
                    {this.props.uiFormsState.sectionMessage}
                  </div>
                )}
                <div>
                  <CpButton
                    btnType="secondary"
                    onClick={this.props.toggleShowTaxForm}
                  >
                    {this.props.showTaxForm
                      ? "View source form"
                      : "View tax form"}
                  </CpButton>
                </div>
              </div>
            </div>
            {this.props.showTaxForm ? (
              <TaxFormPages
                formId={this.props.sourceFormLayout.taxForm}
                pages={this.state.svgPages}
                clientId={client.id}
                noticeId={noticeId}
                resolutionCaseId={resolutionCaseId}
                versionId={version}
                revisionId={revision}
              />
            ) : (
              <>
                <div className="overlay-subheader">
                  {this.props.section.name}
                </div>
                <div className="overlay-section">
                  <Section
                    actions={{
                      ...this.actions,
                      updateAnswer: partial(this.actions.updateAnswer, {
                        clientId: client.id,
                        resolutionCaseId,
                        noticeId,
                        sourceFormId: selectedForm.id,
                        sectionId,
                        versionId: version ? version : null,
                        revisionId: revision ? revision : null,
                        answers: this.props.answers,
                      }),
                    }}
                    scrollJump={this.props.scrollJump}
                    answers={this.props.answers}
                    clientId={client.id}
                    formName={this.props.sourceFormLayout.name}
                    resolutionCaseId={resolutionCaseId}
                    sourceFormId={selectedForm.id}
                    noticeId={noticeId}
                    taxFormId={this.props.sourceFormLayout.taxForm}
                    activeSection={this.props.section}
                    questionsToShowRequiredWarnings={
                      this.props.questionsToShowRequiredWarnings
                    }
                    sections={this.props.sourceFormLayout.sections}
                    summaryTable={this.props.summaryTable}
                    alternateAnswerSets={alternateAnswerSets}
                    overrideAnswerSet={overrideAnswerSet}
                    version={version}
                    revision={revision}
                    folderIds={get(this.props, "folderIds").map(
                      (val) => formSetMap[val]
                    )}
                    taskId={get(this.props, "taskId", null)}
                    taxPrep={true}
                    dynamicData={this.props.dynamicData}
                  />
                </div>
                <OverlaySectionFooter
                  taxFormId={this.props.sourceFormLayout.taxForm}
                  activeSection={this.props.section}
                  sections={this.props.sourceFormLayout.sections}
                  updateSection={this.updateSection}
                  showTaxForm={this.props.toggleShowTaxForm}
                  sourceFormLayout={sourceFormLayout}
                />
              </>
            )}
          </div>
        </>
      </Scoped>
    );
  }
}

const css = k`
  .overlay-section {
    background: var(--cps-color-card-background);
    border: solid var(--cps-color-border);
    border-width: 0 1px 0 1px;
    box-shadow: 0px 10px 0 -24px rgba(75, 78, 83, 0.4);
  }

  .source-form-top-bar {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: var(--cps-color-card-background);
    border: solid var(--cps-color-border);
    border-width: 1px 1px 0 1px;
    box-shadow: 0px 10px 0 -24px rgba(75, 78, 83, 0.4);
  }

  .overlay-subheader {
    font-size: 1.6rem;
    padding: 1.6rem 2.4rem 1.6rem 2.4rem;
    border: solid var(--cps-color-border);
    border-width: 0 1px 0 1px;
    font-weight: 400;
    padding-left: 2.4rem;
    background-color: var(--cps-color-medium-well);
  }

  .sf-top-bar-name {
    font-size: 20px;
    padding: 16px;
  }

  .sf-top-bar-right {
    display: flex;
    padding: 16px;
  }

  .save-to-canopy-files-icon {
    color: var(--cps-color-secondary-text);
  }
`;
