import React from "react";
import { connect } from "react-redux";

import { CpLoader } from "canopy-styleguide!sofe";
import TaxPrepPicker from "./tax-prep-picker.component.js";
import SequenceFilter from "../tax-forms/sequence-filter.component.js";
import { NavContent } from "primary-navbar!sofe";
import styles from "./form-view-switch.style.css";
import { generalToast, infoToast } from "toast-service!sofe";
import * as sentryErrorLogging from "error-logging!sofe";
import { trackUsageEvent } from "./tracked-usage.resource.js";
import { noop, includes, chain, toLower } from "lodash";
import { catchAsyncStacktrace } from "auto-trace";
import { setFormSet, updateSequenceFilter } from "./version.actions.js";
// import Parcel from "single-spa-react/parcel";

@connect((state) => ({
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  formSet: state.smeVersion.formSet,
  sequenceFilter: state.smeVersion.sequenceFilter,
}))
export default class FormViewSwitch extends React.PureComponent {
  render() {
    const {
      sequenceFilter,
      formSet,
      formSwitchLeft,
      formView,
      clientId,
      resolutionCaseId,
      taxFormId,
      sourceFormId,
      sectionId,
      userIsSME,
    } = this.props;
    const isTaxPrep = this.props.isTaxPrep;

    let href = `#/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/sourceforms/${sourceFormId}`;

    if (formView === "sourceForm") {
      if (isTaxPrep) {
        href += `/tax-prep/${formSet}`;
      } else {
        href += `/taxforms/${taxFormId}?sectionId=${sectionId}`;
      }
    } else if (formView === "taxForm") {
      href += `/sections/${sectionId}`;
    }

    return (
      <span>
        <NavContent
          hasTopnavSecondary={false}
          clientMenuPossible={true}
          className={`${styles.formSwitch}`}
          useNavContentClass={false}
          topStyleAttribute={"top"}
          style={{ left: formSwitchLeft, marginTop: "24px" }}
        >
          <a
            href={href}
            className={styles.link}
            onClick={onClick.bind(
              null,
              formView,
              sourceFormId,
              taxFormId,
              userIsSME,
              isTaxPrep
            )}
          >
            {(!isTaxPrep || formView === "sourceForm") && (
              <img
                className={`${styles.formSwitchImage}`}
                src={
                  formView === "sourceForm"
                    ? "https://cdn.canopytax.com/static/end-user-forms-ui/Tax_Form_Icon.png"
                    : "https://cdn.canopytax.com/static/end-user-forms-ui/View_Source_Form_Icon.png"
                }
              />
            )}
            <div className={`${styles.formSwitchSpan}`}>
              {formView === "sourceForm"
                ? isTaxPrep
                  ? "View Tax Return"
                  : "View Tax Form"
                : "View Source Form"}
            </div>
          </a>
          {isTaxPrep && formView !== "sourceForm" && (
            <div style={{ height: "100%" }}>
              <div style={{ width: 180 }}>
                <TaxPrepPicker
                  formSet={formSet}
                  onChange={(changed) =>
                    this.props.dispatch(setFormSet(changed))
                  }
                />
                <div style={{ marginTop: 16 }}>
                  <SequenceFilter
                    sequenceFilter={sequenceFilter}
                    onChange={(sequence) =>
                      this.props.dispatch(updateSequenceFilter(sequence))
                    }
                  />
                </div>
                {/*<div style={{ marginLeft: -12 }}>*/}
                {/*  <Parcel*/}
                {/*    config={() =>*/}
                {/*      SystemJS.import("xml-download-parcel!sofe")*/}
                {/*        .then((module) => module.default())*/}
                {/*        .catch(catchAsyncStacktrace())*/}
                {/*    }*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
              {this.getTaxFormIndex()}
            </div>
          )}
        </NavContent>
      </span>
    );
  }

  getTaxFormIndex() {
    const { forms, dispatch, formSwitchLeft } = this.props;

    if (!forms)
      return (
        <div style={{ marginTop: 16 }}>
          <CpLoader />
        </div>
      );

    const visibleTaxForms = chain(forms)
      .filter(
        (form) =>
          form.visible === true ||
          includes(toLower(form.visible), "visible") ||
          includes(form.visible, true)
      )
      .sortBy("sequence")
      .value();

    return (
      <div
        className={styles.taxFormIndex}
        style={{
          width: Math.min(
            window.innerWidth -
              formSwitchLeft.substring(0, formSwitchLeft.indexOf("px")) -
              20,
            250
          ),
        }}
      >
        {visibleTaxForms.length ? (
          visibleTaxForms.map((form) => (
            <a
              title={form.name}
              onClick={() => {
                dispatch(updateSequenceFilter(form.sequence));
              }}
              className={
                styles.taxFormLink +
                " " +
                (this.props.sequenceFilter === form.sequence && form.sequence
                  ? styles.active
                  : "")
              }
              key={form.name}
            >
              {!form.sequence ? (
                <i
                  title="Warning: no sequence number defined!"
                  className="cps-icon cps-icon-error cps-yellow"
                />
              ) : null}{" "}
              {form.name}
            </a>
          ))
        ) : (
          <center>
            There are no tax-forms
            <br />
            visible in this return
          </center>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    const { sourceFormId, sourceFormName } = this.props;

    /* Check if we're navigating away from the current formView towards the other formView. In other words, switching between
     * the source form and the tax form views.
     */
    if (
      this.props.formView === "taxForm" &&
      includes(window.location.href, `/sourceforms/${this.props.sourceFormId}/`)
    ) {
      trackUsageEvent("Changed_Form_View", "View_Web_Form", {
        sourceFormId,
        sourceFormName,
      }).subscribe(noop, catchAsyncStacktrace());
    } else if (
      this.props.formView === "sourceForm" &&
      includes(window.location.href, `/taxforms/${this.props.taxFormId}`)
    ) {
      trackUsageEvent("Changed_Form_View", "View_Tax_Form", {
        sourceFormId,
        sourceFormName,
      }).subscribe(noop, catchAsyncStacktrace());
    }
  }
}

function onClick(
  formView,
  sourceFormId,
  taxFormId,
  userIsSME,
  event,
  isTaxPrep
) {
  if (
    !isTaxPrep &&
    formView === "sourceForm" &&
    (!taxFormId || taxFormId === "null")
  ) {
    if (userIsSME) {
      infoToast(
        `This source form has not been connected to a tax form. Please go edit the source form in the SME tool and connect it to a tax form.`,
        `This is truly regrettable and I am deeply remorseful`,
        null,
        7000
      );
    } else {
      generalToast(
        `The tax form is not currently available. We're working fast to fix it.`
      );
      sentryErrorLogging.captureException(
        `Source form ${sourceFormId} does not have a tax form associated with it`
      );
    }

    event.preventDefault();
    return false;
  }
}
