import React from "react";
import styles from "./summary-table.styles.css";
import {
  rowIsSelected,
  addRow,
  removeRow,
  editRow,
  restoreRow,
  allDeleted,
} from "./summary-table.helpers.js";
import { CprButton } from "canopy-styleguide!sofe";
import { get } from "lodash";
import { fromEvent } from "rxjs";
import { Scoped, k } from "kremling";
import { summaryTableValidate } from "../questions/required.helpers.js";
import SummaryTableRowKebab from "./summary-table-row-kebab.component.js";
import AnimatedTr from "src/source-forms/summary-table/animated-table-row.component.js";
import { successToast } from "toast-service!sofe";

export default class Table extends React.Component {
  state = {
    openDropdown: null,
  };

  setOpenedDropdown(rowIndex) {
    this.setState({ openDropdown: rowIndex });
  }

  componentDidMount() {
    this.click$ = fromEvent(window, "click").subscribe((_) =>
      this.setState({ openDropdown: null })
    );
  }

  componentWillUnmount() {
    this.click$ && this.click$.unsubscribe();
  }

  render() {
    const {
      context,
      columns,
      summaryTableName,
      summary,
      serverAnswer,
      fullName,
      question,
      actions,
      blocks,
      summaryRef,
      buttonText,
      editingIndex,
      sectionResize,
      setEditing,
    } = this.props;

    return (
      <>
        <div className={`cps-card-table cps-card ${styles.tableWrapper}`}>
          <table>
            {getHeader(columns)}
            <tbody>
              {getRows(
                columns,
                summaryTableName,
                summary,
                serverAnswer,
                fullName,
                question,
                actions,
                blocks,
                summaryRef,
                this.setOpenedDropdown.bind(this),
                this.state.openDropdown,
                this.props.children,
                setEditing
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.actionBlock}>
          <CprButton
            actionType="flat"
            ref={this.buttonRef}
            style={{
              border: rowIsSelected(editingIndex) ? "none" : "auto",
            }}
            disabled={
              !allDeleted(get(serverAnswer, "answers", true)) &&
              rowIsSelected(editingIndex)
            }
            onClick={(...args) => {
              setTimeout(sectionResize);
              addRow(
                serverAnswer,
                actions.updateAnswer,
                fullName,
                question,
                ...args
              );
            }}
          >
            {buttonText}
          </CprButton>
        </div>
      </>
    );
  }
}

function getHeader(columns) {
  return (
    <thead>
      <tr>
        {columns.map((col) => (
          <th key={col.id + col.label}>{col.label}</th>
        ))}
        <th key="end" />
      </tr>
      <tr>
        <th colSpan={columns.length + 1} />
      </tr>
    </thead>
  );
}

function getRows(
  columns,
  summaryTableName,
  summaryAnswers,
  serverAnswer,
  fullName,
  question,
  actions,
  blocks,
  summaryTableRef,
  setOpenDropdown,
  openDropdown,
  summaryTableBlocks,
  setEditing
) {
  if (
    !summaryAnswers ||
    !summaryAnswers.length ||
    allDeleted(serverAnswer.answers)
  ) {
    return <Scoped css={css}>{renderEmpty(columns)}</Scoped>;
  } else {
    const { editingIndex } = serverAnswer;

    return summaryAnswers.map((answers, rowIndex) => {
      const editingRow = editingIndex === rowIndex;

      const rowIsDeleted =
        get(serverAnswer, `answers[${rowIndex}]._isDeleted`) === true;

      const rowBaseStyle = editingRow
        ? {
            borderBottom: "none",
          }
        : {};

      const kebabMenu = (
        <SummaryTableRowKebab
          key={`kebabMenu-${answers[0]}-${answers[1]}-${rowIndex}`}
          editingIndex={editingIndex}
          rowIndex={rowIndex}
          openDropdown={openDropdown}
          setRowIndex={setOpenDropdown}
          editRow={editRow.bind(
            null,
            serverAnswer,
            actions.updateAnswer,
            fullName,
            rowIndex,
            question,
            blocks,
            summaryTableValidate,
            summaryTableRef,
            setEditing
          )}
          removeRow={() => {
            removeRow(
              serverAnswer,
              actions.updateAnswer,
              fullName,
              rowIndex,
              question
            );
            successToast("Row deleted", "Undo", () => {
              restoreRow(
                actions.updateAnswer,
                serverAnswer,
                fullName,
                question,
                rowIndex
              );
            });
          }}
        />
      );

      return editingRow && rowIsSelected(editingIndex) ? (
        <React.Fragment key={`tableRow-${rowIndex}`}>
          <AnimatedTr
            show={!rowIsDeleted}
            rowIndex={rowIndex}
            rowBaseStyle={rowBaseStyle}
            answers={answers}
          />
          <Scoped css={css}>
            <tr>
              <td className="block-cell" colSpan={columns.length + 1}>
                {summaryTableBlocks}
              </td>
            </tr>
          </Scoped>
        </React.Fragment>
      ) : (
        <AnimatedTr
          key={`tableRow-${answers[0]}-${answers[1]}-${rowIndex}`}
          show={!rowIsDeleted}
          rowIndex={rowIndex}
          rowBaseStyle={rowBaseStyle}
          answers={answers}
        >
          {kebabMenu}
        </AnimatedTr>
      );
    });
  }
}

function renderEmpty(columns) {
  return (
    <tr>
      <td colSpan={columns.length + 1} key="end">
        <div className="empty">No entries</div>
      </td>
    </tr>
  );
}

const css = k`
  .src-source-forms-summary-table-summary-table-blocks-styles__questionBlock {
    margin: 0;
    width: 100%;
    animation: slideOpen;
    transition: "all 1s ease-in";
  }

  @keyframes slideOpen {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .cps-card-table .block-cell {
    padding: 0;
  }

  .empty {
    display: flex;
    justify-content: center;
    font-style: italic;
    color: var(--cps-color-primary-text);
  }
`;
