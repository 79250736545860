import React, { useEffect, useState } from "react";
import { find, get } from "lodash";
import { merge } from "rxjs";
import { map, mergeMap, reduce, toArray } from "rxjs/operators";

import Select from "cpr-select";
import styles from "./question.styles.css";

import { updateSourceFormSectionAnswers } from "src/source-forms/sections/source-form-sections.resource.js";
import { generateOptionsList, findGroup } from "./dynamic-dropdown.helpers.js";
import { getSourceFormGroups } from "src/source-forms/source-form-groups.resource.js";
import { catchAsyncStacktrace } from "auto-trace";

export default function DynamicDropdown(props) {
  const [actualSource, setActualSource] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  /*
    take the form and section and post empty to get the summary table
  */

  const {
    clientId,
    question,
    actions,
    version,
    revision,
    resolutionCaseId,
    noticeId,
  } = props;

  const isSummaryTable =
    get(question, "dropdownInstance.dataSource.type") === "SUMMARY_TABLE";

  useEffect(() => {
    const mountConditions =
      question.dropdownInstance && question.dropdownInstance.dataSourceParams;

    let network$ = null;

    if (isSummaryTable && mountConditions) {
      /* fetch the Summary table */
      network$ = updateSourceFormSectionAnswers({
        clientId,
        resolutionCaseId,
        versionId: version,
        revisionId: revision,
        sourceFormId: question.dropdownInstance.dataSourceParams.formId,
        sectionId: question.dropdownInstance.dataSourceParams.sectionId,
        answers: {},
        groupId: question.dropdownInstance.dataSourceParams.groupId,
        noticeId,
      })
        .pipe(
          map(
            (sourceFormData) =>
              sourceFormData.answers[
                get(question, "dropdownInstance.dataSource.name")
              ]
          )
        )
        .subscribe((source) => setActualSource(source));
    } else if (mountConditions) {
      /* use the datasource params to determine which forms are part of this multiform set */
      network$ = getSourceFormGroups(
        clientId,
        resolutionCaseId,
        version,
        revision,
        noticeId
      )
        .pipe(
          mergeMap((groups) => {
            const dataSourceGroup = findGroup(
              groups,
              question.dropdownInstance.dataSourceParams.groupId
            );

            const relevantForms = dataSourceGroup
              ? dataSourceGroup.forms.filter(
                  (form) =>
                    form.formId === question.dropdownInstance.dataSource.formId
                )
              : [];

            return merge(
              ...relevantForms.map((form, i) => {
                return merge(
                  ...question.dropdownInstance.sections.map(({ id }) =>
                    updateSourceFormSectionAnswers({
                      clientId,
                      resolutionCaseId,
                      versionId: version,
                      revisionId: revision,
                      sourceFormId: `${form.formId}:${i}`,
                      sectionId: id,
                      answers: {},
                      groupId:
                        question.dropdownInstance.dataSourceParams.groupId,
                      noticeId,
                    })
                  )
                ).pipe(
                  reduce(
                    (acc, curr) => ({
                      ...acc,
                      sourceFormId: curr.sourceFormId,
                      answers: { ...acc.answers, ...curr.answers },
                    }),
                    {}
                  )
                );
              })
            );
          }),
          map((formData) => ({
            sourceFormId: formData.sourceFormId,
            answers: formData.answers,
          })),
          toArray()
        )
        .subscribe((answers) => {
          setActualSource(answers);
        }, catchAsyncStacktrace());
    }

    return () => network$ && network$.unsubscribe();
  }, []);

  if (!question.dropdownInstance) return null;

  const selectOptions = actualSource
    ? generateOptionsList(actualSource, question.dropdownInstance)
    : [];

  const selectAnswer = (answer) => {
    if (!answer) return;

    const fullAnswer = isSummaryTable
      ? find(actualSource.answers, (a) => a._rowId === answer)
      : find(actualSource, (a) => a.sourceFormId === answer);

    setSelectedAnswer(fullAnswer);

    // commit the selected option to the store
    actions.selectDataSet({
      answer,
      answerData: fullAnswer,
      mappings: question.dropdownInstance.mappings,
      sectionId: !props.paramSourceFormId
        ? props.answers.serverAnswers._rowId
        : question.dropdownInstance.sectionId,
      formId: props.paramSourceFormId || props.parentName,
    });
  };

  return (
    <div className={`cps-form-group ${styles.formGroup}`}>
      <div
        style={{
          width: "50%",
        }}
      >
        <label className="cps-body-sm cps-wt-bold cps-color-monsoon">
          {question.dropdownInstance.dropdownLabel}
        </label>
        <div
          style={{
            wordSpacing: "24px",
          }}
          className={`${styles.inputBox}`}
        >
          <Select
            zIndex={2}
            options={selectOptions}
            onChange={selectAnswer}
            selected={get(
              selectedAnswer,
              isSummaryTable ? "_rowId" : "sourceFormId",
              0
            )}
          />
        </div>
      </div>
    </div>
  );
}
