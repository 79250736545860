import { asyncStacktrace } from "auto-trace";
import { updateSourceFormSectionAnswers } from "../sections/source-form-sections.resource.js";
import { updateSourceFormSubsectionAnswers } from "../sections/source-form-subsections.resource.js";
import { ReplaySubject, Subject } from "rxjs";
import * as types from "./answer.types.js";
import {
  isEqual,
  isFinite,
  isNil,
  isNumber,
  isPlainObject,
  get,
  toString,
  isEmpty,
} from "lodash";
import { updateSourceFormSection } from "../sections/section.actions.js";
import {
  doneSavingAnswer,
  newSourceFormLayout,
  savingAnswer,
} from "../source-form-layout.actions.js";
import { resetSectionMessage } from "../ui-forms.actions.js";
import { transformTableBeforePatch } from "src/source-forms/summary-table/summary-table.helpers.js";
import {
  captureSpecialValue,
  requestIsForCurrentRoute,
  specialConfigAttributes,
} from "./answer.helpers.js";

/*
  this is VERY similar to update answer, however
  it is specifically for the tabular entry summary table form
*/
export function updateMultiple(
  requestObj,
  question,
  answers,
  dispatch,
  useRedux = false
) {
  if (useRedux) {
    dispatch(savingAnswer());
    /*
      this handles the sourceform front-end side of things.
      It's equivalent to patchServerAnswers but doesn't bother with
      the expanded single-entry summary table form so it shouldn't have
      to mess with redux as much...hopefully 😅
    */
    dispatch(patchServerMultipleAnswersNoEditIndex(question.name, answers));
  }
  const resourceFunction = isNumber(requestObj.subsectionIndex)
    ? updateSourceFormSubsectionAnswers
    : updateSourceFormSectionAnswers;

  resourceFunction({
    ...requestObj,
    answers: { [question.name]: { answers } },
  }).subscribe(
    (result) => {
      if (useRedux) {
        dispatch(doneSavingAnswer());
        if (requestIsForCurrentRoute(requestObj)) {
          dispatch(newAnswersRetrieved(result));
        }
      }
    },
    asyncStacktrace((err) => {
      if (useRedux) {
        dispatch(resetSectionMessage());
        dispatch(doneSavingAnswer());
      }
      throw err;
    })
  );
}

export function updateAnswer(
  requestObj,
  oldAnswer,
  question,
  answer,
  extraActions = {},
  requestObjOverrides = {},
  extraAnswers = {}
) {
  return (dispatch, getState) => {
    /* Empty strings should not be saved to the database in cases like when the the user
     * is tabbing through unanswered questions.
     * EXCEPTION: ignore this rule if there's a default calc value.
     */

    let calcDefaultValue;
    const namespace = get(question, "meta.calculatedDefaultNamespace");
    if (namespace) {
      calcDefaultValue = get(requestObj, `answers.serverAnswers.${namespace}`);
    }
    if (isFinite(parseFloat(calcDefaultValue))) {
      // remove the comma or ampersand for fair conditional comparisons below
      calcDefaultValue = calcDefaultValue.replace(/[, %]+/g, "");
    }

    if (
      (isEqual(answer, oldAnswer) &&
        !isNil(answer) &&
        answer !== "" &&
        isEmpty(extraAnswers)) ||
      ((answer === "" || isNil(answer)) &&
        isNil(oldAnswer) &&
        !get(question, "meta.defaultValue")) ||
      (toString(answer) === calcDefaultValue && isNil(oldAnswer))
    ) {
      return;
    }

    const name = extraActions.specificQuestion || question.name;
    dispatch(savingAnswer());

    /* We know that the server answer is going to be this, but it isn't yet. This avoids race conditions
     * in summary tables where a blur event and a click event on Continue are racing.
     */
    dispatch(patchServerAnswers(name, answer));
    const resourceFunction = isNumber(requestObj.subsectionIndex)
      ? updateSourceFormSubsectionAnswers
      : updateSourceFormSectionAnswers;

    const params = {
      ...requestObj,
      ...requestObjOverrides,
    };

    /* Saving summary tables is a tricky beast. There is very nuanced behavior that we do not want
     * to handle here, but in the summary table helpers. See https://github.com/CanopyTax/form-service/wiki/Source-Form-Summary-Tables
     * https://canopytax.atlassian.net/browse/TRFORMS-478 and https://canopytax.atlassian.net/browse/TRFORMS-480
     */
    const isSummaryTable =
      answer &&
      (Array.isArray(answer.summary) || isPlainObject(answer.summary));
    const specificAnswer = isSummaryTable
      ? transformTableBeforePatch(answer, extraActions)
      : answer;

    return resourceFunction({
      ...params,
      answers: {
        ...captureSpecialValue(
          specialConfigAttributes.INITIAL_VALUE_NAMESPACE,
          question,
          getState()
        ),
        ...captureSpecialValue(
          specialConfigAttributes.INITIAL_TIMESTAMP_NAMESPACE,
          question,
          getState()
        ),
        [name]: specificAnswer,
        ...extraAnswers,
      },
    }).subscribe(
      (result) => {
        dispatch(doneSavingAnswer());

        if (requestIsForCurrentRoute(requestObj)) {
          dispatch(newAnswersRetrieved(result));
          handleExtraActions(dispatch, name, result, extraActions);
        }
      },

      asyncStacktrace((err) => {
        dispatch(resetSectionMessage());
        dispatch(doneSavingAnswer());
        throw err;
      })
    );
  };
}

export const sectionMountedObservable = new Subject(); // sends updates to tax prep when a section mounts
export const fromEndUserFormsObservable = new Subject();
export const toEndUserFormsObservable = new Subject();
export const answerAsObservable = new ReplaySubject(1); // sends updates TO tax prep
export const answerLoadingAsObservable = new ReplaySubject(1); // sends updates TO tax prep (for answers)
export const forceFetchSectionAsObservable = new ReplaySubject(1); // sends updates FROM tax prep

export function patchServerMultipleAnswersNoEditIndex(questionName, answers) {
  return {
    type: types.PATCH_SERVER_ANSWER,
    question: questionName,
    answer: {
      answers,
    },
  };
}

export function patchServerAnswers(questionName, answer) {
  return {
    type: types.PATCH_SERVER_ANSWER,
    question: questionName,
    answer,
  };
}

/** Used for both sections and subsections, after a GET or POST answers
 */
export function newAnswersRetrieved(result) {
  return (dispatch) => {
    dispatch(newSourceFormLayout(result.sourceFormLayout));
    dispatch(updateSourceFormSection(result.section || result.subsection));
    dispatch(resetSectionMessage());

    dispatch(newServerAnswers(result.answers));

    answerAsObservable.next(result);
  };
}

export function newServerAnswers(answers) {
  return {
    type: types.NEW_SERVER_ANSWERS,
    answers,
  };
}

function handleExtraActions(dispatch, name, result, extraActions) {
  if (extraActions.updateSummaryTable) {
    dispatch({
      type: types.UPDATED_SUMMARY_TABLE,
      summary: result.answers[name].summary,
      answers: result.answers[name].answers,
      editingIndex: result.answers[name].editingIndex,
      name,
    });
  }
}
