import React from "react";
import CprSelect from "cpr-select";
import { catchAsyncStacktrace } from "auto-trace";
import { get, find } from "lodash";
import { getPractitioners } from "./practitioner.resource.js";
import CanopyInput from "./canopy-input.decorator.js";
import AsyncDecorator from "async-decorator/rx6";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import styles from "./inputs.style.css";
import { toggle } from "kremling";

@AsyncDecorator
@CanopyInput()
export default class Practitioner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      practitioners: [],
      localAnswer: props.answer,
    };
    this.inputRef = React.createRef();
  }
  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getPractitioners(this.props.clientId).subscribe((practitioners) =>
        this.setState({
          practitioners: practitioners || [],
        })
      )
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer) {
      this.setState({ localAnswer: this.props.answer });
    }
  }
  focusInput = () => {
    const input = get(this, "inputRef.current.el");
    if (input) {
      if (input.querySelector("input")) input.querySelector("input").focus();
      else console.warn("no input to focus on!");
    } else console.warn("no ref to focus on!");
  };
  render() {
    const selectedPractitioner = find(
      this.state.practitioners,
      (pract) => pract.id === this.state.localAnswer
    );

    return (
      <CprSelect
        ref={this.inputRef}
        options={[{ key: null, value: null }].concat(
          this.state.practitioners.map(toSelectItem)
        )}
        onChange={(practitionerId) => {
          this.setState({
            localAnswer: practitionerId,
          });
          this.props.updateAnswer(this.props.question, practitionerId, {
            useServerResponse: true,
          });
        }}
        selected={selectedPractitioner ? selectedPractitioner.id : undefined}
        outerClass={toggle(
          fixedTableStyles.fixedTableSelectOuter,
          styles.selectOuter,
          this.props.inTable
        )}
        inputClass={this.props.question.name}
      />
    );
  }
}

export function toSelectItem(practitioner) {
  return {
    key: practitioner.id,
    value: practitioner.name,
  };
}
