import React from "react";
import { func, string, bool } from "prop-types";
import { CprIcon } from "canopy-styleguide!sofe";

import styles from "./drawer.styles.css";

const animationDelayMS = 300;

export default class DrawerTitle extends React.Component {
  state = {
    shouldAnimateCloseButton: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isDrawerOpen !== this.props.isDrawerOpen) {
      setTimeout(this.animateCloseButton, animationDelayMS);
    }
  }

  render() {
    return (
      <div className={styles.drawerTitle}>
        <button className={styles.drawerBtn} onClick={this.props.onClose}>
          <CprIcon name="caret-large-left" />
        </button>
        <div
          className={`cps-subheader cps-wt-semibold ${styles.ellipsis}`}
          title={this.props.title}
        >
          {this.props.title}
        </div>
      </div>
    );
  }

  animateCloseButton = () => {
    this.setState((prevState) => ({
      shouldAnimateCloseButton: !prevState.shouldAnimateCloseButton,
    }));
  };
}

DrawerTitle.propTypes = {
  onClose: func.isRequired,
  title: string.isRequired,
  isDrawerOpen: bool.isRequired,
};
