import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from "./answer-set-viewer.style.css";
import * as actions from "./active-question.actions.js";
import {
  questionHasAnotherDataSource,
  getAnswerSetName,
} from "./answer-set-viewer.helpers.js";
import { CprTooltip } from "canopy-styleguide!sofe";
import { isNil, partial } from "lodash";

class AnswerSetViewer extends React.Component {
  constructor(props) {
    super(props);

    this.boundActions = {
      ...bindActionCreators(actions, props.dispatch),
    };

    this.state = {
      open: false,
    };
  }

  render() {
    const {
      actions,
      question,
      overrideAnswerSet,
      alternateAnswerSets,
      activeQuestionFromAPI,
    } = this.props;

    if (
      !overrideAnswerSet ||
      !alternateAnswerSets ||
      alternateAnswerSets.length === 0
    ) {
      return null;
    }

    let rollbackAnswerSets = alternateAnswerSets || [];
    const usedAnswerSetIndex = rollbackAnswerSets.indexOf(question.answerSet);
    if (usedAnswerSetIndex >= 0) {
      rollbackAnswerSets = rollbackAnswerSets.slice(usedAnswerSetIndex + 1);
    }

    const answerSetToRollbackTo = (rollbackAnswerSets || []).find(
      partial(activeQuestionHasAnswer, activeQuestionFromAPI.activeQuestion)
    );

    // Someday we might be able to roll back to both the Client Survey Value or Client Record Value. But that day is not this day.
    const answerSetsToRollbackTo = answerSetToRollbackTo
      ? [answerSetToRollbackTo]
      : [];

    return questionHasAnotherDataSource(question, overrideAnswerSet) ? (
      <CprTooltip
        html={getAnswerSetName(question.answerSet, true)}
        disabled={this.state.open && answerSetsToRollbackTo.length > 0}
      >
        <div
          className={`cps-dropdown ${this.state.open ? "cps-open" : ""}`}
          data-testid="dropdown"
          onClick={question.isOverridden ? turnOn.bind(this, question) : null}
        >
          <div className={`cps-btn-icon ${styles.buttonIcon}`}>
            <a className={`cps-link cps-margin-top-0`}>
              <span
                className={`cps-icon cps-icon-survey cps-color-secondary-text ${
                  question.isOverridden ? "cps-info" : ""
                }`}
              />
            </a>
          </div>
          {answerSetsToRollbackTo.length > 0 && (
            <ul className="cps-dropdown-menu" role="menu">
              {answerSetsToRollbackTo.map((answerSet) => {
                return (
                  <li key={answerSet}>
                    <a
                      onClick={partial(
                        removeOverride,
                        actions,
                        question,
                        alternateAnswerSets,
                        overrideAnswerSet
                      )}
                    >
                      {getAnswerSetName(answerSet)}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </CprTooltip>
    ) : (
      <div data-testid="noButtonIcon" className={`${styles.noButtonIcon}`} />
    );
  }
}

// For usage in the application
export default connect((state) => ({
  activeQuestionFromAPI: state.activeQuestion,
}))(AnswerSetViewer);

// For usage in tests
export { AnswerSetViewer };

function removeOverride(
  actions,
  question,
  alternateAnswerSets,
  overrideAnswerSet
) {
  const updateAnswerParams = {
    answerSet: overrideAnswerSet,
    answerOrder: [overrideAnswerSet].concat(alternateAnswerSets),
  };

  const REMOVE_OVERRIDE = null;
  const extraActions = {
    useServerResponse: true,
  };

  actions.updateAnswer(
    question,
    REMOVE_OVERRIDE,
    extraActions,
    updateAnswerParams
  );
}

function turnOn(question, e) {
  e.stopPropagation();
  e.preventDefault();

  const newState = {
    open: true,
  };

  if (!this.state.bodyClickHandler) {
    const eventHandler = turnOff.bind(this);
    document.addEventListener("click", eventHandler);
    newState.bodyClickHandler = eventHandler;
  }

  if (!this.state.open) {
    this.boundActions.getActiveQuestion(question.name);
  }

  this.setState(newState);
}

function turnOff() {
  document.removeEventListener("click", this.state.bodyClickHandler);

  this.setState({
    open: false,
    bodyClickHandler: null,
  });

  this.boundActions.clearActiveQuestion();
}

function activeQuestionHasAnswer(activeQuestionFromAPI, answerSet) {
  const result =
    activeQuestionFromAPI &&
    activeQuestionFromAPI.answerSets &&
    !isNil(activeQuestionFromAPI.answerSets[answerSet]);
  return !!result;
}
