import React from "react";
import CprSelect from "cpr-select";

export default function TaxPrepPicker({ formSet, onChange }) {
  return (
    <div>
      <CprSelect
        options={[
          {
            value: "Federal",
            key: "federal",
          },
          {
            value: "Alabama",
            key: "alabama",
          },
          {
            value: "Alaska",
            key: "alaska",
          },
          {
            value: "Arizona",
            key: "arizona",
          },
          {
            value: "Arkansas",
            key: "arkansas",
          },
          {
            value: "California",
            key: "california",
          },
          {
            value: "Colorado",
            key: "colorado",
          },
          {
            value: "Connecticut",
            key: "connecticut",
          },
          {
            value: "Delaware",
            key: "delaware",
          },
          {
            value: "Florida",
            key: "florida",
          },
          {
            value: "Georgia",
            key: "georgia",
          },
          {
            value: "Hawaii",
            key: "hawaii",
          },
          {
            value: "Idaho",
            key: "idaho",
          },
          {
            value: "Illinois",
            key: "illinois",
          },
          {
            value: "Indiana",
            key: "indiana",
          },
          {
            value: "Iowa",
            key: "iowa",
          },
          {
            value: "Kansas",
            key: "kansas",
          },
          {
            value: "Kentucky",
            key: "kentucky",
          },
          {
            value: "Louisiana",
            key: "louisiana",
          },
          {
            value: "Maine",
            key: "maine",
          },
          {
            value: "Maryland",
            key: "maryland",
          },
          {
            value: "Massachusetts",
            key: "massachusetts",
          },
          {
            value: "Michigan",
            key: "michigan",
          },
          {
            value: "Minnesota",
            key: "minnesota",
          },
          {
            value: "Mississippi",
            key: "mississippi",
          },
          {
            value: "Missouri",
            key: "missouri",
          },
          {
            value: "Montana",
            key: "montana",
          },
          {
            value: "Nebraska",
            key: "nebraska",
          },
          {
            value: "Nevada",
            key: "nevada",
          },
          {
            value: "New Hampshire",
            key: "new hampshire",
          },
          {
            value: "New Jersey",
            key: "new jersey",
          },
          {
            value: "New Mexico",
            key: "new mexico",
          },
          {
            value: "New York",
            key: "new york",
          },
          {
            value: "North Carolina",
            key: "north carolina",
          },
          {
            value: "North Dakota",
            key: "north dakota",
          },
          {
            value: "Ohio",
            key: "ohio",
          },
          {
            value: "Oklahoma",
            key: "oklahoma",
          },
          {
            value: "Oregon",
            key: "oregon",
          },
          {
            value: "Pennsylvania",
            key: "pennsylvania",
          },
          {
            value: "Rhode Island",
            key: "rhode island",
          },
          {
            value: "South Carolina",
            key: "south carolina",
          },
          {
            value: "South Dakota",
            key: "south dakota",
          },
          {
            value: "Tennessee",
            key: "tennessee",
          },
          {
            value: "Texas",
            key: "texas",
          },
          {
            value: "Utah",
            key: "utah",
          },
          {
            value: "Vermont",
            key: "vermont",
          },
          {
            value: "Virginia",
            key: "virginia",
          },
          {
            value: "Washington",
            key: "washington",
          },
          {
            value: "West Virginia",
            key: "west virginia",
          },
          {
            value: "Wisconsin",
            key: "wisconsin",
          },
          {
            value: "Wyoming",
            key: "wyoming",
          },
        ]}
        onChange={onChange}
        placeholder="Select form set"
        selected={formSet}
      />
    </div>
  );
}
