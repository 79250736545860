import { getSection, putSection } from "./program-sections.resource.js";
import * as types from "./program-section.types.js";
import { successToast } from "toast-service!sofe";
import { catchAsyncStacktrace, syncStacktrace } from "auto-trace";

export function getProgramSection(clientId, resolutionCaseId, sectionId) {
  if (!clientId) {
    throw new Error(`Cannot retrieve resolution case section without clientId`);
  }

  if (!resolutionCaseId) {
    throw new Error(
      `Cannot retrieve resolution case section without resolution case`
    );
  }

  if (!sectionId) {
    throw new Error(
      `Cannot retrieve resolution case section without section id`
    );
  }

  return (dispatch) => {
    dispatch({
      type: types.FETCHING_ACTIVE_PROGRAM_SECTION,
      sectionId,
    });

    getSection(clientId, resolutionCaseId, sectionId)
      .then((section) => {
        dispatch({
          type: types.UPDATE_ACTIVE_PROGRAM_SECTION,
          section,
        });
      })
      .catch((ex) => {
        dispatch({
          type: types.CANCEL_FETCH_ACTIVE_PROGRAM_SECTION,
        });

        throw syncStacktrace(ex);
      });
  };
}

export function updateProgramSectionStatus(
  clientId,
  resolutionCase,
  programSlug,
  sectionId,
  section,
  newStatus,
  notifiees
) {
  if (["in_progress", "needs_review", "complete"].indexOf(newStatus) < 0) {
    throw new Error(
      `section statuses must be either 'in_progress', 'needs_review', or 'complete'`
    );
  }

  if (!clientId) {
    throw new Error(`Cannot update resolution case section without clientId`);
  }

  if (typeof resolutionCase !== "object" || !resolutionCase.id) {
    throw new Error(
      `Cannot update resolution case section without resolution case that has an id`
    );
  }

  if (!sectionId) {
    throw new Error(`Cannot update resolution case section without section id`);
  }

  if (!section) {
    throw new Error(`Cannot update resolution case section without section`);
  }

  if (notifiees && !Array.isArray(notifiees)) {
    throw new Error(
      `Cannot update resolution case section -- notifiees is not an Array`
    );
  }

  const notifications = notifiees
    ? {
        url: window.location.href,
        users: notifiees,
      }
    : null;

  return (dispatch) => {
    section.status = newStatus;
    putSection(clientId, resolutionCase.id, sectionId, section, notifications)
      .then(() => {
        dispatch({
          type: types.UPDATE_ACTIVE_PROGRAM_SECTION,
          section,
        });

        resolutionCase.program_data.programs
          .find((program) => program.slug === programSlug)
          .sections.find(
            (sectionSummary) => section.id === sectionSummary.id
          ).status = section.status;

        successToast(`Status changed successfully.`);
      })
      .catch(catchAsyncStacktrace());
  };
}
