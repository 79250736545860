import React from "react";
import { ReplaySubject, zip } from "rxjs";
import { catchAsyncStacktrace } from "auto-trace";

/*
 * Note that this decorator only works properly if clientMenuPossible and
 * hasTopnavSecondary never change from their initial values
 */
export default function decorate(propsToOptions) {
  if (typeof propsToOptions !== "function") {
    throw new Error(`propsToOptions function is required`);
  }
  return function (WrappedComponent) {
    return class WithContentTop extends React.Component {
      constructor() {
        super();
        this.state = {
          navContentTop: 0,
        };
      }

      componentDidMount() {
        const { hasTopnavSecondary, clientMenuPossible } = propsToOptions(
          this.props
        );
        if (hasTopnavSecondary === "undefined") {
          throw new Error(
            "WithContentTop requires hasTopnavSecondary option/prop"
          );
        }
        if (hasTopnavSecondary === "undefined") {
          throw new Error(
            "WithContentTop requires clientMenuPossible option/prop"
          );
        }
        const SECONDARY_NAV_HEIGHT = 56;

        // A promise that returns an observable for the height of the primary-navbar
        const pnhObservablePromise = SystemJS.import(
          "primary-navbar!sofe"
        ).then((mod) => {
          return mod.primaryNavHeightObs;
        });

        // A promise that returns an observable for the height of the secondary navbar (including client menu)
        let secondaryNavHeightObservablePromise;
        // If we create our own observable/subject for the height, we'll need to clean it up when we're done
        let secondaryNavHeightSubject;

        if (hasTopnavSecondary) {
          secondaryNavHeightSubject = new ReplaySubject(1);
          secondaryNavHeightSubject.next(SECONDARY_NAV_HEIGHT);
          secondaryNavHeightObservablePromise = Promise.resolve(
            secondaryNavHeightSubject
          );
        } else if (clientMenuPossible) {
          if (window.loggedInUser.role === "Client") {
            // Clients do not see the client menu
            secondaryNavHeightSubject = new ReplaySubject(1);
            secondaryNavHeightSubject.next(0);
            secondaryNavHeightObservablePromise = Promise.resolve(
              secondaryNavHeightSubject
            );
          } else {
            secondaryNavHeightObservablePromise = SystemJS.import(
              "client-menu!sofe"
            ).then((mod) => {
              return mod.clientMenuHeightObs;
            });
          }
        } else {
          // There is no secondary nav
          secondaryNavHeightSubject = new ReplaySubject(1);
          secondaryNavHeightSubject.next(0);
          secondaryNavHeightObservablePromise = Promise.resolve(
            secondaryNavHeightSubject
          );
        }

        this.secondaryNavHeightSubject = secondaryNavHeightSubject;

        Promise.all([pnhObservablePromise, secondaryNavHeightObservablePromise])
          .then((values) => {
            const [primaryNavHeightObservable, secondaryNavHeightObservable] =
              values;
            // Now combine the two observables together.
            this.disposable = zip(
              primaryNavHeightObservable,
              secondaryNavHeightObservable
            ).subscribe((values) => {
              const [primaryNavHeight, secondaryNavHeight] = values;
              this.setState({
                navContentTop: primaryNavHeight + secondaryNavHeight,
              });
            });
          })
          .catch(catchAsyncStacktrace());
      }
      componentWillUnmount() {
        this.mounted = false;

        if (this.disposable) {
          this.disposable.unsubscribe();
        }

        if (this.secondaryNavHeightSubject) {
          this.secondaryNavHeightSubject.complete();
        }
      }
      render() {
        return (
          <WrappedComponent
            {...this.props}
            contentTop={this.state.navContentTop}
          />
        );
      }
    };
  };
}
