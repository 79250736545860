import React from "react";
import { always, toggle } from "kremling";
import { CprTooltip } from "canopy-styleguide!sofe";

import styles from "./quick-look.styles.css";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import DrawerContainer from "../../common/drawer/drawer-container.component";
import DrawerTitle from "../../common/drawer/drawer-title.component";
import QuickLookSection from "../../tax-prep/quick-look-section.component";
import { clamp } from "../../common/multi-line-truncation-helper.js";
import { QuickLookDispatcher } from "src/source-forms/bulk-insert/quicklook-dispatcher.js";

export default class QuickLook extends React.Component {
  state = {
    isQuickLookOpen: false,
    disableTooltip: true,
  };

  constructor() {
    super();
    this.textRef = React.createRef();
  }

  componentDidMount() {
    clamp(this.textRef.current, {
      clamp: 2.1,
      onTruncate: this.enableTooltip,
      useNativeClamp: false,
    });
  }

  render() {
    const {
      question,
      clientId,
      resolutionCaseId,
      noticeId,
      version,
      revision,
      folderIds,
      taskId,
      sourceFormId,
      inTable,
    } = this.props;

    return (
      <React.Fragment>
        <div
          className={always(`search-${question.name}`).toggle(
            fixedTableStyles.fixedTableQuicklook,
            styles.quickLook,
            inTable
          )}
          onClick={this.openQuickLook}
          onKeyDown={this.handleSpacebarPress}
          tabIndex="0"
        >
          <CprTooltip
            html={question.label}
            disabled={this.state.disableTooltip}
            delayTime={600}
          >
            <div
              className={toggle(
                fixedTableStyles.fixedTableQuicklookMaxWidth,
                styles.maxWidth,
                inTable
              )}
              ref={this.textRef}
            >
              {question.label}
            </div>
          </CprTooltip>
          <i className="cps-icon cps-icon-right-caret" />
        </div>
        <DrawerContainer
          isOpen={this.state.isQuickLookOpen}
          onClose={this.closeQuickLook}
        >
          <DrawerTitle
            isDrawerOpen={this.state.isQuickLookOpen}
            title={question.label}
            onClose={this.closeQuickLook}
          />
          <QuickLookDispatcher.Provider
            value={{
              quickLookOpen: this.state.isQuickLookOpen,
              closeQuickLook: this.closeQuickLook,
            }}
          >
            <QuickLookSection
              clientId={clientId}
              resolutionCaseId={resolutionCaseId}
              noticeId={noticeId}
              version={version}
              revision={revision}
              sourceFormId={sourceFormId}
              sectionId={question.quickLookSectionReference}
              folderIds={folderIds}
              taskId={taskId}
              closeQuickLook={this.closeQuickLook}
              isQuickLookOpen={this.state.isQuickLookOpen}
            />
          </QuickLookDispatcher.Provider>
        </DrawerContainer>
      </React.Fragment>
    );
  }

  openQuickLook = () => {
    this.setState({
      isQuickLookOpen: true,
    });
  };

  closeQuickLook = () => {
    this.setState(
      {
        isQuickLookOpen: false,
      },
      () => this.props.actions.updateAnswer(null, this.props.question)
    );
  };

  enableTooltip = () => {
    this.setState({ disableTooltip: false });
  };

  handleSpacebarPress = (event) => {
    if (event.key === " " && !this.state.isQuickLookOpen) {
      event.preventDefault();
      this.openQuickLook();
    }
  };
}
